import React from "react";

const NotFoundPage = () => {
  return(
    <section>
      <h1>Not Found Page!</h1>
      <p>The information you seek is not here</p>
    </section>
  )
}

export default NotFoundPage;